@import 'colors';

.activation-step-div {
    margin-top: 15px;
}

.activation-steps {
    width: 100%;
    height: 54px;

    ul {
        font-size: 11px;
        margin-top: 0px;
        margin-left: 8px;
    }

    &.expanded {
        height: 100px;
    }

    p {
        position: absolute;
        left: 46px;
        top: 2px;
        font-size: 14px !important;
        margin: 0px !important;
        width: auto !important;
        display: auto !important;

        &.selected {
            font-weight: bold;
        }

        &.non_export {
            top: 12px;
        }
    }
}

.line-height-22 {
    line-height: 22px !important;
}

.circle-step {
    font-size: 35px;

    p {
        font-size: 11px !important;
        font-weight: bold;
        position: absolute;
        left: 14px;
        top: 32px;
        margin-top: -28px !important;
        color: black;
    }

    &.selected {
        color: $cinq-green;
    }

    &.selected-next {
        position: absolute;
    }
}

.horizontal-rule {
    position: absolute;
    margin-left: 17px;
    margin-top: -1px;
    border-left: 2px solid black;
    height: 22px;

    &.extended {
        height: 66px;
    }
}

.full-width {
    width: 100% !important;
}

.menu-width {
    width: 260px;
}

.separator {
    color: black;
    width: 100%;
    margin-top: 20px;
}

.activation-footer {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 415px);
    height: 42px;
    background-color: #fafafa;
    border-top: 0.5px solid #305d62;
    padding: 20px 30px 20px 30px;
    z-index: 100;
}

.activation-cancel-button {
    color: rgb(48, 93, 98);
    border: 1px solid rgb(48, 93, 98);
    background-color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    width: 100px;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
}

.activation-continue-button {
    background-color: rgb(48, 93, 98);
    border: 1px solid rgb(48, 93, 98);
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    width: 200px;

    &.smaller {
        width: 100px;
        padding: 5px;
    }

    &:hover {
        cursor: pointer;
    }
}

.activation-to {
    width: 80px;
    text-align: center;
}

label {
    &.activation {
        position: relative;
        top: -6px;
        left: 5px;
    }
}

input {
    &.activation {
        min-width: auto !important;
    }

    &.activation-input {
        min-width: auto !important;
        width: 400px;
    }

    &.date-range {
        min-width: auto !important;
        width: 150px;
    }
}

select {
    &.activation-select {
        min-width: auto !important;
        width: 412px;

        option:disabled {
            color: lightgrey;
        }
    }
}

.activation_image_wrapper {
    display: flex;
    height: 122px;
    align-items: center;

    .activation-image {
        margin-top: -5px;
        height: 50px;
        max-width: 270px;
    }
}


.billing-table {
    width: 100%;

    input[type="checkbox" i] {
        min-width: 18px;
        margin-bottom: 20px;

        &.error {
            outline: 1px solid red;
        }
    }

    label {
        position: absolute;
        margin-left: 30px;
        margin-top: -42px;
    }
}

.billing-price-platform {
    margin: 0px 54px;
}

.filter-this-audience {
    color: #2F5D62;
    text-transform: capitalize;
    background: 0;
    border: 0;
    line-height: 14px;
    font-size: 14px;
    margin: 0px 0px 0px 5px;
    padding: 0;
    letter-spacing: normal;
    width: auto;
    border-radius: 0px;
    position: relative;
    z-index: 1;

    &:hover {
        border-bottom: 1px solid #2F5D62;
    }

    .fa-filter {
        padding: 0px 5px 0px 0px;
    }
}

.platform_filters_wrapper {
    button {
        margin: 6px 0px 0px 0px;
        display: inline-block;
    }
}

hr {
    &.reporting_hr {
        margin: 30px 0px;
    }
}

.terms-wrapper {
    width: calc(100% - 40px);
    height: 26vh;
    overflow-y: scroll;
    border: 1px solid #2F5D62;
    border-radius: 4px;
    padding: 16px 24px 16px 16px;
    margin-bottom: 16px;
}

.payment-panel {
    min-height: 600px;
}

#braintree-drop-in-div {
    max-width: 500px;
    margin-top: 40px;
}

.activate-audience-title {
    background-color: #fafafa;
    font-size: 25px;
    padding: 20px;
    margin: 0px;
    border-bottom: 1px solid darkgray;
}

.internal-activation-box {
    padding-top: 20px;
    padding-left: 10px;

    i {
        margin-left: 18px;
    }
}

.fa-check {
    &.final-activated-check {
        margin-top: 8px;
    }
}

.my-acts {
    width: auto !important;
    margin: 0px !important;
    float: left !important;
    font-size: 12px !important;
    text-align: left !important;
    line-height: 16px !important;
    margin-top: 8px !important;
}

/* bootstrap mimic */
.row {
    width: 100%;
}

.p-0 {
    padding: 20px;
}

.p-1 {
    padding: 5%;

    &.sides-only {
        padding-top: 1%;
        padding-bottom: 1%;
    }
}

.p-2 {
    padding: 10%;
}

.p-3 {
    padding: 15%;
}

.p-4 {
    padding: 20%;
}

.mt-0 {
    margin-top: 20px;
}

.mt-1 {
    margin-top: 5%;
}

.mt-2 {
    margin-top: 10%;
}

.mt-3 {
    margin-top: 15%;
}

.mt-4 {
    margin-top: 20%;
}

.mb-0 {
    margin-bottom: 20px;
}

.mb-1 {
    margin-bottom: 5%;
}

.mb-2 {
    margin-bottom: 10%;
}

.mb-3 {
    margin-bottom: 15%;
}

.mb-4 {
    margin-bottom: 20%;
}

.ml-0 {
    margin-left: 20px;
}

.ml-1 {
    margin-left: 5%;
}

.ml-2 {
    margin-left: 10%;
}

.ml-3 {
    margin-left: 15%;
}

.ml-4 {
    margin-left: 20%;
}

.mr-0 {
    margin-right: 20px;
}

.mr-1 {
    margin-right: 5%;
}

.mr-2 {
    margin-right: 10%;
}

.mr-3 {
    margin-right: 15%;
}

.mr-4 {
    margin-right: 20%;
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.inline {
    display: inline !important;
}

.text-center {
    text-align: center !important;
}

.alert-danger {
    background-color: red;
    color: white;
}

.add-note {
    float: left;
    width: 100%;

    p {
        font-size: 16px;
        font-weight: bold;
    }

    textarea {
        width: 100%;
        padding: 10px;
        font-family: "nimbus", "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }

    input {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 10px;
    }
}

.activationsExports {
    overflow-x: scroll;
}

.activationExportTable {
    display: inline-table;

    thead {
        tr {
            height: 65px !important;
        }
    }

    tr {
        display: flex;
        height: 90px !important;
    }

    tbody {
        tr {
            border-bottom: 1px solid #b7b7b7;
            width: fit-content;
            padding: 0px 0px 0px 10px;

            &:hover {
                background-color: #f6f6f6;
            }
        }
    }

    th:last-child {
        width: 163px !important;

        &.super_admin {
          width: 158px !important;      
        }
    }

    th {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 125px;
        font-weight: 100;
        border-top: 1px solid #676767;
        border-bottom: 1px solid #676767;
        padding-top: 8px;
        color: #030303;
        font-size: 15px;

        &.extra_room {
            min-width: 225px;
        }

        &.requestor {
          min-width: 225px;
          max-width: 225px;
        }

        &.titles {
            min-width: 180px;
            max-width: 180px;
        }
      
        &.popup_static_width {
            min-width: 175px;
        }
        
        p {
            width: min-content;
            text-align: center;
        }
    }

    th:first-child {
      justify-content: left;
      text-align: left;
      &.admin {
        padding-left: 15px;
      }
      &.client, &.admin_no_activate {
        padding-left: 10px;
      }
    }

    td {
        display: flex !important;
        align-items: center !important;
        justify-content: center;
        min-width: 125px;
        max-width: 125px;
        text-transform: capitalize;
        text-align: center;
        text-wrap: pretty;
        color: #48494b;
        font-size: 14px;

        &.no_capitalization {
            text-transform: none;
        }

        &.platformLauncherDeleteWrapper {
          min-width: 160px;
        }

        .fa-trash {
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
        
        button {
          //padding-top: 11px;
        }

        &.extra_room {
            min-width: 225px;
            max-width: 225px;
        }
        
        &.requestor {
          min-width: 225px;
          max-width: 225px;
          word-wrap: break-word;
          word-break: break-all;

          p {
            margin: 0;
            padding: 0px 30px;
          }
        }

        &.titles {
            min-width: 180px;
            max-width: 180px;
        }

        &.popup_static_width {
            min-width: 175px;
            max-width: 175px; 
            text-align: center;
        }

        &.platform {
            text-transform: capitalize;
        }

        img {
            width: 85px; 
            height: auto; 

            &.platform_x {
                width: 45px;
            }
        }
    }

    td:first-child {
      justify-content: left;
      text-align: left;
    }

    td:last-child {
        justify-content: right;
        min-width: 162px;
    }

    button {
        height: 32px;
        width: 120px;
        min-width: 120px;        
        font-size: 13px;
        border: 0;
        border-radius: 5px;
        text-transform: capitalize;

        &.build {
            background: #ffc101;
            margin: 0px 15px 0px 0px;
            padding-top: 10px;

            &:hover {
                background: #3dc6bf;
                color: white;
            }

            p {
                text-wrap: nowrap;
            }
        }

        &.platforms {
            background: #3dc6bf;
            margin: 0px 15px 0px 0px;

            &:hover {
                background: #315d62;
                color: white;
            }

            p {
                text-wrap: nowrap;
            }
        }

        &.exports {
            background: #315d62;
            margin: 0px 15px 0px 0px;
            
            &:hover {
                background: #3dc6bf;
                color: white;
            }
        }

        &.activate {
            margin-top: 4px;
            padding-top: 10px;

            &:hover {
                color: white;
                background: #3dc6bf;
            }
        }

        p {
            margin-top: -1px;

            &.client {
                margin-top: 3px;
            }
        }
    }
    

    .filterPopup {
        display: flex;
        justify-content: center;
        align-items: center;

        .staticFilters {
           width: 115px;
        }

        .fa-info-circle {
          color: #568bb1;
          font-size: 16px;
        }
    
        &:hover {
            .staticFilters {
                display: none;
            }

            .fa-info-circle { 
              display: none;
            }    
               
            .filterPopupWrapper {
                display: block;
            }
        }
    }

    .filterPopupWrapper {
        border: 1px solid #a6a6a6;
        padding: 0px 15px;
        border-radius: 22px;
        width: 190px;
        min-width: 190px;
        position: relative;
        background: white;
        display: none;
        z-index: 1;

        &.notes {
          width: 170px;
          max-width: 170px;
          min-width: 170px;
        }

        p {
            text-align: left;
            line-height: 20px;
            font-size: 11px;

            &.filter_title {
              font-size: 13px;
              color: #404040;
              font-weight: bold;
            }
        }

        &:hover {
          display: block;
        }
    }
}