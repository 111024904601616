.popup_overlay {
  align-items: center;
  background: rgba(0,0,0,.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity .2s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 100000;

  &.expanded {
    display: flex;
    opacity: 1;
    visibility: visible;
  }

  .popup_widget {
    background: #fff;
    border-radius: 6px;
    padding: 50px;
    position: relative;
    width: auto;

    .fa-times {
      color: black;
      background: none;
      border: 0;
      padding: 0;
      width: fit-content;
      position: absolute;
      right: 18px;
      top: 18px;
      width: 22px;
      cursor: pointer;
    }

    h2 {
      text-align: center;
      margin: 20px 0px;
    }

    #usa_map_holder {
      zoom: .9;

      #usa_map {
        height: 400px;
        position: relative;
        width: 750px;
      }
    }

    .user-reset {
      color: red;

      .login_entry_wrapper {
        margin: 9px 0 35px;

        input {
          margin-bottom: 6px;
        }
        label {
          display: none;
          border: 0;
          font-size: 15px;
          margin: 5px 0 0;

          &.error {
            color: red;
             &.show {
              display: block;
            }
          }
        }
      }
      .submit_wrapper {
        button {
          border: none;
        }
      }
    }

    p.build {
      width: 500px;
      line-height: 28px;
    }

    button.build {
      background: #ffc101;
      margin: 0px 15px 0px 15px !important;
      padding-top: 10px;
      border: 0;

      &:hover {
        background: #3dc6bf;
        color: white;
      }
    }

    &.save_audience {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 400px;

      h3 {
        font-size: 24px;
      }

      p {
        text-align: center;
      }

      input {
        margin-top: 15px;
      }

      label {
        width: 211px;

        &.error {
          color: red;
          font-size: 13px;
          border: 0;
          margin: 8px 0px 0px 0px;
          display: none;

          &.show {
            display: block;
          }
        }
      }

      .widget-filter-display {
        width: fit-content;
        max-height: 150px;
        overflow-y: auto;

        .entry_wrapper {
          margin: 25px 35px;
        }

        p {
          line-height: 26px;
        }
      }
      
      .entry_wrapper {
        margin: 15px 0 35px;

        input {
          margin-bottom: 15px;
        }

        label {
          border: 0;
          font-size: 15px;
          margin: 0px 0px 6px;
        }
      }
    }

    .popup_submit_wrapper {
      text-align: center;
      margin: 25px 0px 0px;

      button {
        border: none;
        padding-top: 10px;

        p {
          margin: 0;
        }
      }
    }

    .auth-link {
      display: none;
    }
  }
}