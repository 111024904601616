/*  Admin Dashboard Sandbox   
    _______________________

    Use this stylesheet if there are any questions regarding
    location in admin_dashboard.scss. Changes to this file 
    will only reflect within the AdminDashboard.

    Note: Due to the layering of the classes, you may need to 
    add an '!important' for new changes to take priority.

    * Please add changes within the .admin_dashboard {}.

    _______________________
*/

.admin_dashboard {

    /* This is the old admin-dashboard.scss 
Removing its reference changed alot. Needs further investigation/consolidation */

    #admin-dashboard {
        width: 900px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }

    .admin-menu {
        border-right: 0.5px solid #e6e6e6;
        height: 100vh;
        left: 0;
        top: 0;
        padding-top: 16px;
        position: fixed;
        width: 50px;
        overflow: hidden;
        border-right: 1px solid lightgray;
        width: 142px;
        margin-top: 90px;

        p,
        .menu-option {
            float: left;
            text-align: left;
            margin: 14px 0px 14px 32px;
            width: 100%;
            font-size: 14px;
            font-size-adjust: none;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .admin-header-name {
        font-size: 20px;
        margin-top: 3px;
        margin-left: 20px;
        float: left;
        display: flex;

        .admin {
            margin-left: 15px;
            color: #d83939;
        }

        .name {
            float: left;
            margin-left: 30px;
            font-size: 24px;
            padding-top: 1px;
            font-weight: bold;
            margin-top: -9px;
        }
    }

    .admin-dashboard-body {

        margin-left: 150px;
        padding-right: 100px;
        padding-top: 85px;
        padding-left: 65px;

        h2 {
            color: #1c1c1c;
            font-weight: 100;
            font-size: 1.2em;
            margin-bottom: 40px;
        }

    }

    .admin-stat-div {
        width: 24%;
        height: 200px;
        float: left;
        margin-right: 60px;
        margin-bottom: 80px;
    }

    .admin-stat-box {
        border: solid #e6e6e6 .5px;
        border-radius: 22px;
        float: left;
        padding: 20px;
        margin-right: 30px;
        background-color: antiquewhite;
        color: darkred;
        width: 200px;
        padding-bottom: 0px;
        height: 200px;
        margin-top: 0px;
        position: absolute;
        zoom: 100%;
        transition: zoom .5s ease-in-out;

        &:hover {
            cursor: pointer;
            zoom: 101%;
            transition: zoom .5s ease-in-out;
        }

        h3 {
            margin-top: 5px;
            padding-bottom: 4px;
            color: #d83939;
        }

        ul {
            margin-top: 0px;
            padding-left: 0px;

            li {
                &.total {
                    padding-top: 15px;
                    border-top: .5px solid #e1d4c2;
                    margin-top: 13px;
                }

            }
        }
    }

    .view-change-admin {
        position: absolute;
        bottom: 130px;
    }

    .audience-attributes {
        width: 200px;
        float: left;
        margin-right: 65px;
    }

    .audience-attributes-list {
        p {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .audience-attribute-details {
        width: 700px;
        float: left;
        border: .2px solid #e1d4c2;
        height: 500px;
        border-radius: 5px;
    }

    .img-div {
        margin-top: 55px;
        margin-left: 55px;
    }

    .admin-categories {
        height: 500px;
    }

    .admin-category-table {
        overflow-y: scroll;
        height: 400px;
    }

    table.search-result-table {
        margin-top: 20px;
        margin-bottom: 40px;

        th {
            height: 50px;
            text-decoration: underline;
        }

        td {
            min-width: 100px;
            padding: 3px;

            &.header {
                width: 200px;
            }

            &.desc {
                max-width: 400px;
            }
        }
    }

    .save-icon {
        font-size: 25px;
        margin-left: 5px;
        margin-bottom: 15px;
    }

    .audience-display {
        width: 82vw;
        height: 80vh;
        padding: 0px 30px 30px 0px;
        overflow: scroll;

        td {
            i {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .popup_overlay {
        button {
            margin: 0px 0px 0px 0px !important;
        }
    }

    .auth-form-popup {
        h2 {
            height: 40px;
            margin: -40px 0px 20px 0px;
        }

        .submit_wrapper {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .login_entry_wrapper {
            margin-bottom: 5px;
        }

        label {
            &.error {
                color: red;
            }
        }

        input {
            margin: 5px 0px 0px 0px !important;
        }

        .forgot-div {
            margin-top: 0px;
        }

        a {
            color: rgb(85, 26, 139);
        }

        p {
            color: rgb(85, 26, 139);

            &:hover {
                cursor: unset;
                color: rgb(85, 26, 139);
            }
        }

        label {
            width: 100%;
            color: white;
            text-align: left;
            margin-top: 20px;

            &.error {
                font-size: 15px;
                border: 0;
                margin: 5px 0px 0px 0px;
                display: none !important;

                &.show {
                    display: block !important;
                }
            }
        }
    }


    /* This is the old admin-users.scss 
Removing its reference changed alot. Needs further investigation/consolidation */

    .user-list-display {
        p {
            float: left;

            &.user-status {
                font-size: 14px;
                margin-top: 19px;
                color: darkred;

                &.active {
                    color: darkgreen;
                }
            }

        }

        i {
            font-size: 14px;

            &.disabled {
                color: lightgray;
            }
        }

        .fa-check-to-slot {
            &:hover {
              cursor: pointer;
            }
        }

        .fa-trash {
            &:hover {
                color: red;
            }
        }
    }

    .admin-list-wrapper {
        width: 80vw;
        overflow-x: scroll;
    }

    .admin-list {
        width: 2000px;
    }

    .user-list-display {
        float: left;

        button.invite-users {
          border: 0; 
          margin-left: 18px; 

          p {
            margin: 0px;
            padding-top: 4px;
          }
        }
    }
}