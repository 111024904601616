/*  Admin Dashboard   
    _______________

    This is the main css class for the AdminDashboard.
    Changes to this file will only reflect within the AdminDashboard.

    * Please add changes within the .admin_dashboard {}.

    _______________
*/


.admin_dashboard {

  .client-dashboard-top-header {
    background-color: #222;
    color: #fff;
    height: 64px;
    padding-left: 50px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;
    display: flex;
    align-items: center;

    .header_logo_wrapper {
      background: none;
      border: none;
      height: inherit;

      .cinq-logo {
        height: 29px;
      }
    }

    .client-header-option-div {
      float: left;

      .admin-header-name {
        display: flex;
        float: left;
        font-size: 20px;
        margin: 0;

        .admin {
          color: #d83939;
          margin-left: 15px;
        }
      }
    }
  }

  .admin-menu {
    .notification-circle {
      background-color: #0da8c6;
      border-radius: 10px;
      color: #fff;
      height: 20px;
      margin-left: -25px;
      margin-top: -20px;
      position: absolute;
      text-align: center;
      width: 20px;
      line-height: 23px;
    }
  }

  .tabs {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #a9a9a9;

    .user-list-display table {
      border: none;
    }
  }

  .user-list-display {
    overflow-x: scroll;

    .user-list-display-organization {
      border-bottom: 1px solid #a9a9a9;
      padding: 20px 0px 20px 20px;

      .organization-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .organization-header-wrapper { 
          display: flex;
          align-self: flex-start;
          margin-top: 20px;

          h2 {
            font-weight: 900;
            margin-bottom: 10px;
            margin-right: 10px;
            width: 100%;
            margin: 0;
          }

          i {
            margin-left: 10px;
          }
        }
       
        ul {
          display: flex;
          padding: 0;

          p {
            font-size: 12px;
            width: auto;
          }

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 95px;
            margin-right: 12px;
            
            label {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 45px;
              text-align: center;
              border-bottom: 1px solid #a9a9a9;
              font-size: 14px;
              padding-bottom: 10px;
            }

            select {
              width: 100px;
              min-width: 100px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  .admin-list,
  .audience-display,
  .user-list-display,
  .feedback {
    h3 {
      color: #222;
      margin-bottom: 8px;
    }

    table {
      width: 90%;

      tr {
        height: 50px;

        td {
          color: #48494b;
          font-size: 13px;
        }
      }
    }
  }

  .admin-dashboard-body {
    .search-div {
      position: relative;
      width: 80vw;

      .search-text {
        background-color: #e8e8e8;
        border: 1.5px solid #8e8e8e;
        border-bottom: none;
        border-top: none;
        font-size: 15px;
        height: 35px;
        padding: 5px 10px 0 60px;
        width: 100%;
      }

      .fa-magnifying-glass {
        color: #aaacaa;
        font-size: 20px;
        margin-left: 22px;
        margin-top: -30px;
        position: absolute;
      }
    }

    table.feedback {
      width: 100%;
      margin: 20px;

      table {
        width: 90%;

        tr {
          height: 50px;

          td {
            color: #48494b;
            font-size: 14px;
          }
        }
      }
    }
  }

  /* These are for the Organizations page to show the Domains */
  .table {
    display: table;
  }

  .row {
    display: table-row;
  }

  .cell {
    display: table-cell;
    padding: 2px;

    &.domain-error {
      color: red;
      font-weight: bold;
      font-size: 13px;
    }
  }

  .right-cell {
    margin-left: 20px;
  }

  // User download report
  .user-report-downloader {
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 1;

    .user-report-downloader-wrapper {
      display: flex;
    }

    .user-report-downloader-options-wrapper {
      display: flex;
      flex-direction: column;

      .options-row {
        width: 469px;
        display: flex;
        margin: 0px 0px 15px 0px;

        input {
          width: calc(50% - 6px);
        }

        input:first-child {
          margin-right: 12px;
        }

        button {
          height: 32px;
          width: 125px;
        }
      }
    }

    .user-report-downloader-wrapper {
      display: flex;
      align-items: center;

      button {
        width: 100px;
        font-size: 11px;
        border: 0;
        text-transform: capitalize;
        padding: 10px 15px 7px;
        margin: 0px 0px 0px 40px;
      }
    }
  }

  .user_filter_toggle {
    border: 1px solid #aaaaaa;
    background: #f2f2f2;
    display: inline-block;
    border-radius: 10px;
    height: 33px;

    label {
      display: flex;
      height: 33px;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 14px;
      margin: 0;
      padding: 2px 0px 0px 0px;
      width: 100px;
      text-align: center;
      z-index: 50;
      position: relative;
      cursor: pointer;
      transition: color 0.1s ease-in-out;
      transition-delay: 0.15s;
      text-decoration: none !important;
      font-weight: 100;
      border-right: 1px solid #aaaaaa;

      &.init {
        color: white;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &.last {
        border-right: 0px;
      }

      ;

      &.no-border {
        border-right: 0px;
      }

      &:hover {
        background: rgba(#bdbdbd, .35);
      }
    }

    input[type="radio"] {
      display: none;
    }

    .user_toggle_selector {
      position: absolute;
      background: #08beb1;
      color: white;
      height: 31px;
      width: 100px;
      padding: 2px 0px 0px 0px;
      z-index: 2;
      top: 0px;
      border-radius: 9px;
      transition: left 0.25s ease-in-out;

      &.no-border {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    #download_user_all_toggle:checked+label {
      color: white;
    }

    #download_user_all_toggle:checked~.user_toggle_selector {
      left: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    #download_user_active_toggle:checked+label {
      color: white;
    }

    #download_user_active_toggle:checked~.user_toggle_selector {
      left: 101px;
      border-radius: 0px;
    }

    #download_user_inactive_toggle:checked+label {
      color: white;
      border-right: 0;
    }

    #download_user_inactive_toggle:checked~.user_toggle_selector {
      left: 202px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.permissions-td {
  font-size: 10px;
  max-width: 65px !important;
  min-width: 65px !important;
  padding-right: 5px;
  text-align: center;
  padding: 0px !important;

  input {
    font-size: 10px;
    min-width: auto;
    width: 25px;
    height: 15px;
  }
}

.a-td {
  background-color: #aaaaaa10;
}

table.user-list-table {

  input,
  select {
    font-size: 11px;
    min-width: auto !important;
    max-width: 110px;
    text-wrap: balance;
  }

  select {
    padding-right: 5px;
  }

}

.orgs, .admin-list {
  display: flex;
  width: 100%;
  justify-content: left;
  margin: 30px;
  flex-direction: column;

  .user_filter_toggle {
    width: fit-content;
    margin-bottom: 20px;

    label {
      border-right: 0;

      &.init {
        color: white;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
   
    
    
    #download_user_active_toggle:checked + label {
       border-right: 0;
     }

    #download_user_all_toggle:checked + label {
      &:hover {
        border-top-left-radius: 7px;
      }
    }
    #download_user_active_toggle:checked ~ .user_toggle_selector {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  label {
    margin: 18px 0px 8px 0px;
    font-size: 14px;
  }

  select { 
    margin-bottom: 12px;
  }

  button {
    display: block;
    margin-top: 25px;
    border: 0;
    padding-top: 12px;
  }
}

.user-list-table {

  border-collapse: collapse;
  border: 1px solid #000;
  width: 100% !important;

  table,
  td {
    border-collapse: collapse;
    border: 1px solid #000;
  }

  thead {
    display: table;
    width: 100%;
  }

  tbody {
    display: block;
    /* to enable vertical scrolling */
    max-height: 50vh;
    /* e.g. */
    overflow-y: scroll;
    /* keeps the scrollbar even if it doesn't need it; display purpose */
  }

  th,
  td {
    /* to enable "word-break: break-all" */
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    padding: 5px;
    word-break: break-all;
    word-wrap: break-word;
    /* 4. */
    font-size: 14px;
    text-align: center;

    &.user-list-email {
      min-width: 125px !important;
    }
  }

  th {
    border-right: 1px solid transparent;
  }

  th:first-child {
    text-align: left;
    padding-left: 15px;
  }

  td:first-child { 
    text-align: left;
    padding-left: 15px;
  }

  tr {
    display: table;
    /* display purpose; th's border */
    width: 100%;
    box-sizing: border-box;
    /* because of the border (Chrome needs this line, but not FF) */
  }

  td {
    text-align: center;
    border-bottom: none;
    border-left: none;
  }
  
  td:last-child {
    border-right: 0;
  }
}